.react-calendar__navigation {
    display: none;
}

.react-calendar__month-view__weekdays__weekday {
    width: 24px;
    height: 24px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #19181a;
    text-align: center;
    abbr {
        text-decoration: none;
    }
}

.react-calendar__tile {
}

.react-calendar__month-view__days__day {
    width: 24px;
    height: 40px;
    // padding: 0;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 24px;
    color: #19181a;
    font-weight: 400;
    border: 0;
    background-color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
}

.react-calendar__tile--now {
    border-radius: 40px;
    background: #f8f7fa;
    color: #356dff;
    font-weight: 600;
}

.react-calendar__tile--active {
    background: #356dff;
    border-radius: 40px;
    color: white;
    font-weight: 600;
}
