.policy {
    padding: 20px;
    h1 {
        text-align: center;
    }
    h2 {
        text-transform: uppercase;
        display: inline-block;
    }
    ol {
        counter-reset: item;
        li {
            display: block;
            margin-bottom: 15px;
        }
        li:before {
            content: counters(item, ".") ". ";
            counter-increment: item;
        }
        &.lettered {
            list-style: none;
            counter-reset: letters;
            li:before {
                counter-increment: letters;
                content: "(" counter(letters, lower-alpha) ") ";
            }
        }
    }
    .top-level {
        > li:before {
            font-size: 2rem;
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-weight: 500;
            line-height: 1.2;
        }
    }
    table,
    tr,
    td,
    th {
        border: 1px solid grey;
        padding: 5px;
    }
}
