.outer {
    width: 100%;
    height: 100%;

    background: linear-gradient(90.95deg, #242943 -0.72%, #3c59e2 123.31%);

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .back {
        position: absolute;
        top: 36px;
        left: 50px;
        cursor: pointer;
    }

    .progress {
        position: absolute;

        top: 50%;
        left: 0;
        transform: translateY(-50%);

        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 0 10px 10px 0;
        background-color: #414a7e;

        padding: 23px 19px 23px 17px;

        max-width: 280px;
        //min-height: 297px;

        .closeButton {
            position: absolute;
            top: 9px;
            right: 7px;

            cursor: pointer;

            width: 20px;
            height: 20px;

            background: #414a7e;
            opacity: 0.5;
        }

        .message {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;

            color: #ffffff;

            top: 50%;
            right: 10px;

            //transform: translateY(-50%); // rotate(-90deg);
            transform: rotate(-90deg) translateX(70%);
            position: absolute;
            transform-origin: bottom right;
        }

        .courseTitle {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;

            color: #41a5d1;

            padding-bottom: 9.5px;
            padding-left: 8px;
            padding-right: 8px;

            border-bottom: 1px solid #41a5d1;
        }

        .subtitle {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 130%;
            color: #ffffff;

            padding: 16px 8px 12px;
        }

        .items {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 7px;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 144%;

                color: #908fac;

                padding: 3px 7px 2px;

                cursor: pointer;

                &.active {
                    color: #ffffff;
                    background: #418fbd;
                    border-radius: 5px;
                }

                &.done {
                    color: #fff597;
                }
            }
        }

        &.open {
        }
    }

    .inner {
        position: absolute;
        //top: 50%;
        //left: 50%;
        //transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 47px;

        .media {
            padding: 32px 80px 28px;
            background: #41a5d1;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
            border-radius: 20px;

            &.loading {
                width: 672px;
                height: 493px;

                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;

                    animation: shimmer 1s forwards infinite linear;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(211, 211, 211, 0.25) 50%, rgba(255, 255, 255, 0) 100%);
                }

                position: relative;
            }

            .title {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                letter-spacing: -0.1px;
                font-feature-settings: "tnum" on, "lnum" on;

                color: #ffffff;

                margin-bottom: 32px;
            }

            .content {
                width: 100%;

                iframe,
                img {
                    width: 512px;
                    //height: 290px;
                    aspect-ratio: 16/9;
                }
            }

            .details {
                margin-bottom: 22px;
                margin-top: 16px;

                display: flex;
                justify-content: space-between;

                .publisher {
                    display: flex;
                    gap: 6.5px;
                    align-items: center;

                    .logo {
                        width: 36px;
                        height: 36px;
                        border-radius: 2px;
                    }

                    .name {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;

                        color: #ffffff;
                        max-width: 120px;
                    }
                }

                .tags {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;

                    .list {
                        display: flex;
                        flex-direction: row;
                    }

                    .add {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;

                        letter-spacing: 0.0275em;
                        font-feature-settings: "tnum" on, "lnum" on;

                        color: #2c2b5e;

                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap: 4px;

                        cursor: pointer;
                    }
                }
            }

            .about {
                cursor: pointer;

                //background: #FFFFFF;
                //opacity: 0.2;
                background: #67b7da;
                border-radius: 5px;

                padding: 6px;

                display: flex;
                align-items: center;
                justify-content: center;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;

                color: #2c2b5e;
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            gap: 68px;

            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: -0.1px;
            color: #2c2b5e;

            .takeQuiz,
            .skip {
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                cursor: pointer;
            }

            .takeQuiz {
                background: linear-gradient(111.33deg, #5ac7a8 23.39%, #41a5d1 73.37%);

                display: flex;
                align-items: center;
                justify-content: center;

                width: 207px;
                height: 59px;
            }

            .skip {
                background: #e3b275;
                border-radius: 10px;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 121px;
                height: 59px;
            }
        }
    }

    .aboutOuter {
        position: fixed;
        inset: 0 0 0 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(44, 43, 94, 0.5);
        backdrop-filter: blur(5px);

        .aboutInner {
            background: #ffffff;
            border-radius: 20px;
            position: relative;

            .close {
                position: absolute;
                top: 10px;
                right: 12px;
                cursor: pointer;
            }

            font-family: Manrope, sans-serif;
            font-style: normal;
            letter-spacing: -0.1px;

            color: #000000;

            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 27px 33px 33px;

            .title {
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                max-width: 410px;
            }

            .description {
                max-width: 410px;

                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                display: flex;
                align-items: center;
            }
        }
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
