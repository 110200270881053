.page {
    padding: 0;
    padding-top: 24px;
}
.page-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.02em;
    color: #171717;
    margin-bottom: 10px;
}

.page-subtitle {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    margin-bottom: 40px;
}

.page-content {
    label {
        color: #171717;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: 339px;
    }
    input {
        width: 339px;
    }
    .actions-button {
        width: 130px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-top: 30px;
        &.confirm {
            cursor: pointer;
            background: #356dff;
            color: white;
        }
        &.disabled {
            background: grey;
            cursor: not-allowed;
        }
    }
}
@media only screen and (min-width: 992px) {
    .page {
        padding: 40px 50px;
    }
    .page-content {
        label: {
            width: 375px;
        }
        input {
            width: 375px;
        }
    }
}
