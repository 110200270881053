.page {
    height: calc(100% - 85px);
    margin: 0 auto 85px;
    padding-top: 24px;
    .header {
        position: relative;
        margin-bottom: 17px;
        display: flex;

        hr {
            background-color: #555;
            margin: 17px 40px 0 10px;
        }
        img {
            height: 30px;
            border-radius: 10px;
            width: auto;
            margin-top: 5px;
            margin-right: 6px;
            margin-left: 10px;
        }
        .title-wrapper {
            font-size: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 10px;

            max-width: calc(100% - 155px);

            .title {
                font-size: 24px;
                line-height: 44px;
                letter-spacing: -0.02em;
                font-weight: 700;
                font-style: normal;
                font-family: Manrope, sans-serif;
                color: #171717;
            }
            .instructions {
                color: #737373;
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .media-container {
        margin-left: 10px;
        flex-wrap: wrap;
        display: flex;
        .item {
            display: block;
            margin: 10px auto;
            .card {
                margin: 10px auto;
            }
        }
        .light-item {
            margin: 10px 12px 20px 0;
        }
    }
}

.follow {
    background-color: #356dff;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    width: auto;
    height: 40px;
    padding: 10px 16px;
    position: absolute;
    right: 24px;
    top: 15px;
    &.following {
        background-color: white;
        color: #356dff;
        font-weight: 500;
        border: 1px solid #356dff;
    }
}

@media only screen and (min-width: 992px) {
    .page {
        padding: 40px 50px 30px;
        margin: 0 auto;
        height: unset;
        .header {
            img {
                margin-top: 0;
                height: 80px;
            }
            .title-wrapper {
                .title {
                    font-size: 36px;
                }
            }
        }
        .item {
            display: inline-block;
            .card {
                margin: 0 10px;
            }
        }
    }
    .follow {
        right: 40px;
    }
}
