.page {
    margin-bottom: 80px;
    padding-top: 24px;
}
.page-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.02em;
    color: #171717;
    margin-bottom: 10px;
}

.page-subtitle {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    margin-bottom: 40px;
}

.page-content {
    .list-container {
        border: 0.5px dashed #838997;
        border-radius: 6px;
        padding: 16px 12px;
        width: 315px;
        .item {
            width: 290px;
            min-height: 52px;
            background-color: #f9f9f9;
            border-radius: 4px;
            display: flex;
            align-items: center;
            margin: 2px 0;
            justify-content: space-between;
            padding-left: 24px;
            &.selected {
                background: rgba(53, 109, 255, 0.05);
                border-left: 4px solid #356dff;
            }
            .item-title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: "#171717";
                margin-right: 12px;
            }
            .item-check {
                margin-right: 16px;
            }
        }
    }
    .actions-button {
        width: 130px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-top: 30px;
        &.confirm {
            background: #356dff;
            color: white;
        }
    }
}

@media only screen and (min-width: 992px) {
    .page {
        padding: 40px 50px;
        margin-bottom: 0;
    }
    .page-content {
        .list-container {
            padding: 16px;
            width: 382px;
            .item {
                width: 350px;
            }
        }
    }
}
