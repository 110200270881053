.assistant-container {
    width: 275px;
    height: 100vh;
    z-index: 99999;
    overflow: auto;

    opacity: 1;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    padding: 20px 24px;

    display: flex;
    flex-direction: column;

    background: linear-gradient(171.08deg, #2c2b5e 17.38%, #435bc9 76.34%);

    &.transparent {
        opacity: 0;
    }

    .header {
        height: 42px;
        //padding: 20px 24px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        // align-items: center;
        width: 100%;

        .text {
            font-family: Manrope, sans-serif;
            font-style: italic;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            display: flex;
            flex-direction: column;
        }

        .right_btns {
            display: flex;

            .close-btn {
                cursor: pointer;
            }

            .go_back {
                width: 15px;
                height: 15px;
                margin-left: 10px;

                img {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .achievements {
        margin: 16px 0px 30px 0px;
        display: flex;

        border: 0.5px solid #dedede;
        border-radius: 6px;
        justify-content: flex-start;
        height: 58px;
        //width: 326px;
        align-items: center;

        .golden_cup {
            width: 35px;
            height: 35px;
            margin: 17px;

            img {
                width: 35px;
                height: 35px;
            }
        }

        .text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #000000;

            .count {
                font-weight: 700;
            }
        }
    }

    .logo {
        margin-bottom: 20px;
    }

    .content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;

        .section {
            &.bottom {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            width: 100%;

            .extension {
                background: #ffffff;
                border-radius: 30px;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;

                padding: 12px 22px;
                margin: 0 auto 25px;

                cursor: pointer;

                /* RetainIt Blue */

                color: #2c2b5e;
            }

            .section_title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #838997;
                margin: 10px 0 15px;
            }

            .section_content {
                .roulette {
                    background: #e00295;
                    box-shadow: 0px 5px 42px rgba(53, 109, 255, 0.15);
                    border-radius: 6px;
                    display: flex;
                    height: 54px;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 28px;
                    position: relative;
                    cursor: pointer;

                    .dice {
                        width: 30px;
                        height: 30px;
                        margin: 20px;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }

                    .text {
                        color: #ffffff;

                        .primary_text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                        }

                        .secondary_text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 15px;
                            color: rgba(255, 255, 255, 0.8);
                        }
                    }

                    .right_arrow {
                        width: 7px;
                        height: 11px;
                        min-height: 30px;
                        position: absolute;
                        right: 20px;

                        img {
                            width: 7px;
                            height: 11px;
                        }
                    }
                }

                .retain {
                    background: #356dff;
                    box-shadow: 0px 5px 42px rgba(53, 109, 255, 0.15);
                    border-radius: 6px;
                    display: flex;
                    height: 54px;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 28px;
                    position: relative;
                    cursor: pointer;

                    .dice {
                        width: 19px;
                        height: 31px;
                        margin: 20px;

                        img {
                            width: 19px;
                            height: 31px;
                        }
                    }

                    .text {
                        color: #ffffff;

                        .primary_text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                        }

                        .secondary_text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 15px;
                            color: rgba(255, 255, 255, 0.8);
                        }
                    }

                    .right_arrow {
                        width: 8px;
                        height: 5px;
                        min-height: 10px;
                        position: absolute;
                        right: 20px;

                        img {
                            width: 8px;
                            height: 5px;
                        }
                    }
                }

                .launch {
                    background: #ffffff;
                    box-shadow: 0px 5px 42px rgba(53, 109, 255, 0.15);
                    border-radius: 6px;
                    display: flex;
                    height: 54px;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 56px;
                    position: relative;
                    cursor: pointer;

                    .dice {
                        width: 16px;
                        height: 26px;
                        margin: 20px;

                        img {
                            width: 16px;
                            height: 26px;
                        }
                    }

                    .text {
                        color: #356dff;

                        .primary_text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 17px;
                        }

                        .secondary_text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 15px;
                            color: #366dff;
                        }
                    }

                    .right_arrow {
                        min-height: 22px;
                        position: absolute;
                        right: 20px;

                        img {
                            width: 14px;
                            height: 22px;
                        }
                    }
                }

                .menu_actions {
                    padding-bottom: 11.5px;
                    margin-bottom: 11.5px;
                    display: flex;
                    flex-direction: column;
                    //gap: 12px;

                    .action {
                        width: 100%;
                        height: 40px;

                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background: transparent;

                        gap: 10px;

                        //margin: 0 -5px;
                        padding-left: 7px;

                        &.review.active {
                            background: #5ac7a8;
                        }

                        &.library.active {
                            background: #41a5d1;
                        }

                        &.badges.active {
                            background: #ee742c;
                        }

                        &.courses.active {
                            background: #5ac7a8;
                        }

                        &.active {
                            border-radius: 6px;
                            padding-left: 7px;
                            line-height: 15px;
                        }

                        &.active .iconContainer svg {
                            color: #2c2b5e;
                        }

                        .iconContainer {
                            width: 44px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                color: white;
                            }
                        }

                        &.active .actionText {
                            color: #2c2b5e;
                        }

                        .actionText {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;

                            color: #ffffff;

                            flex-grow: 1;
                        }

                        .action-bubble {
                            margin-right: 7px;
                            background: #f55757;
                            border-radius: 46px;
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 17px;
                            color: #ffffff;
                            width: 26px;
                            height: 17px;
                            text-align: center;

                            &.inactive {
                                background: #f8f8f8;
                                border-radius: 46px;
                                font-family: Manrope, sans-serif;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                color: #838997;
                            }
                        }
                    }
                }

                .explore_actions {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    a {
                        width: 100%;
                    }

                    .action {
                        background-color: white;
                        align-items: center;
                        padding-left: 7px;

                        &.active {
                            background-color: #fcf7ed;
                            height: 32px;
                            border-radius: 6px;
                            line-height: 26px;
                        }

                        .icon {
                            width: 19px;
                            height: 16px;

                            img {
                                width: 19px;
                                height: 16px;
                            }
                        }

                        .icon-square {
                            width: 15px;
                            height: 15px;

                            img {
                                width: 15px;
                                height: 15px;
                                margin-left: 3px;
                            }
                        }

                        .action-text {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 15px;
                            margin-left: 15px;
                            color: #000000;
                        }
                    }
                }
            }
        }

        .suggestion {
            background: #eeeeee;
            border-radius: 10px;
            width: 222px;
            //height: 231px;
            padding: 15px 10px;
            margin-bottom: 15px;

            &.upper {
                margin-top: -12px;
            }

            .sugesstion-title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 900;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                margin-top: 12px;
                margin-bottom: 10px;
            }

            .highlight {
                background: #ffffff;
                width: 201px;
                height: auto;
                padding: 8px 10px 8px 10px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                display: flex;
                margin-bottom: 19px;
                align-items: center;
                cursor: pointer;
                position: relative;

                &.blue-btn {
                    background: linear-gradient(180deg, #2b63e7 0%, #1c4fcf 100%);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    height: 46px;
                    color: white;
                }

                &.white-btn {
                    background: #ffffff;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 5px;
                    height: 46px;
                    color: #356dff;
                }

                .icon-a {
                    width: 15px;
                    height: auto;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .icon-b {
                    width: 25px;
                    height: auto;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .icon-c {
                    width: 16px;
                    height: auto;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .icon-d {
                    width: 22px;
                    height: auto;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .scontent {
                    //margin-left: 10px;
                    .circle {
                        width: 20px;
                        /* text-align: center; */
                        height: 20px;
                        border-radius: 50%;
                        background: red;
                        margin-right: 5px;
                        color: white;
                        font-size: 10px;
                        /* vertical-align: middle; */
                        display: inline-flex;
                        margin-left: 20px;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                    }

                    .title {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17px;
                        /* identical to box height */
                        color: #000000;
                        margin-bottom: 6px;

                        .retainit-logo {
                            width: 35px;
                            height: 35px;

                            img {
                                width: 35px;
                                height: auto;
                            }
                        }
                    }

                    .descr {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 13px;
                        color: #000000;
                        margin-right: 30px;
                    }

                    .description {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 13px;
                        color: #000000;
                        margin-top: 15px;

                        ul {
                            font-size: 14px;
                            line-height: 17px;
                            margin-right: 0;
                            margin-left: -17px;
                        }
                    }

                    .button-text {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        margin-left: 15px;
                    }
                }
            }
        }
    }

    .footer {
        background: #002fa7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 17px;
    }

    .suggestions-container {
        position: absolute;
        cursor: pointer;
        height: 50px;
        border-top: 1px solid #eee;
        width: 200px;
        bottom: 0;

        .suggestions-btn {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: flex-start;

            .icon {
                margin-right: 13px;

                img {
                    width: 13px;
                    height: 13px;
                }
            }

            .text {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #898787;
            }
        }
    }
}

.reminder-feedback {
    position: absolute;
    top: 50px;
    z-index: 1;
    left: 0;
}

.assistant-collapsed {
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    left: 0;

    display: flex;
    align-items: center;

    & > * {
        flex-shrink: 0;
    }

    width: 100%;
    height: 80px;
    max-height: 80px;
    overflow: auto;
    padding: 12px 5px;
    background-color: white;
    white-space: nowrap;

    & > * {
        flex-shrink: 0;
    }

    .menu-btn {
        background: #f7f9fe;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        margin: 0 7px;
        cursor: pointer;
        display: inline-block;
        vertical-align: super;
        text-align: center;
        line-height: 35px;

        img {
            vertical-align: middle;
        }
    }

    .menu-btn-large {
        border-radius: 100px;
        width: 185px;
        height: 48px;
        margin: 0 7px;
        cursor: pointer;
        display: inline-block;
        position: relative;

        .extension {
            position: absolute;
            right: 0;
            top: 0;
            width: 45px;
            height: 40px;
        }

        .retainit-small {
            background: #356dff;
            border-radius: 100px;
            display: flex;
            align-items: center;

            &.extended {
                width: 145px;
            }

            img {
                vertical-align: center;
                margin: 8px 12px 8px 12px;
            }

            .text {
                color: #ffffff;

                .primary_text {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

                .secondary_text {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }

        .roulette-small {
            background: #e00295;

            img {
                margin: 8px;
            }
        }

        .launch-small {
            box-shadow: 0px 5px 42px rgb(53 109 255 / 15%);
            background: white;

            .text {
                color: #356dff !important;

                .secondary_text {
                    color: #356dff !important;
                }
            }
        }

        .review-small {
            background: #356dff;

            img {
                padding: 7.5px;
            }
        }

        .library-small {
            background: #b90005;

            img {
                padding: 5px;
            }
        }

        .review-small,
        .library-small,
        .launch-small,
        .roulette-small {
            border-radius: 100px;
            display: flex;
            align-items: center;

            img {
                vertical-align: center;
                margin: 8px;
            }

            .text {
                color: #ffffff;

                .primary_text {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

                .secondary_text {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .assistant-container {
        width: 248px;
        overflow: hidden;

        .achievements {
            width: 208px;
            justify-content: space-around;

            .golden_cup {
                margin: 0;
            }
        }

        .content {
            align-items: center;

            .section {
                width: 214px;

                .section_content {
                    .roulette {
                        justify-content: space-evenly;

                        .dice {
                            margin: 0;
                        }

                        .right_arrow {
                            position: unset;
                            right: unset;
                        }
                    }
                }
            }
        }
    }
}

.burger-menu-container {
    .drops {
        display: flex;
        flex-direction: column;
        border: 0.5px solid #eeeeee;
        box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        margin-right: -10px;
        position: absolute;
        width: 161px;
        right: 20px;
        z-index: 100;
        background-color: white;
        bottom: 64px;

        a {
            margin: 0;
        }

        .btns {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: black;
            display: flex;
            align-items: center;
            height: 36px;
            padding: 0 12px;

            &:hover {
                background-color: #fcf7ed;
            }

            div {
                margin-left: 14px;
            }
        }
    }

    .burger-menu {
        cursor: pointer;
    }
}

.menu-comp {
    display: flex;
    align-items: center;

    margin-top: auto;
    flex-grow: 1;

    .user_initials {
        background: #5ac7a8;
        //mix-blend-mode: multiply;
        border-radius: 60px;
        color: white;
        margin-right: 12px;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
    }

    .userNameContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .user_name {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: white;
        }

        .level {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: white;

            span {
                color: lightgray;
            }
        }
    }

    img {
        filter: invert(1);
    }
}
