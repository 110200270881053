.inner {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding-left: 60px;
    padding-right: 66px;
    padding-bottom: 60px;

    .top {
        margin-top: 65px;
        margin-bottom: 28px;

        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 28px;

        font-family: "Manrope", sans-serif;
        font-style: normal;
        letter-spacing: -0.1px;
        color: #2c2b5e;

        .title {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;

            flex-grow: 1;
        }

        .searchContainer {
            position: relative;

            flex-basis: 225px;
            flex-grow: 0;
            flex-shrink: 1;

            height: 36px;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 18px;
                padding: 5px 32px 7px 12px;
                border: 1px solid #2c2b5e;

                color: #2c2b5e;

                &::placeholder {
                    color: #939395;
                }
            }

            img {
                width: 20px;
                height: 20px;
                z-index: 1;

                margin-right: 12px;
            }
        }

        .sort {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            /* identical to box height */

            color: #2c2b5e;

            display: flex;
            flex-direction: row;
            gap: 10px;

            .arrow {
                cursor: pointer;
                transition: transform 0.2s ease-in-out;

                &.flipped {
                    transform: rotate(180deg);
                }
            }

            .current {
                cursor: pointer;

                position: relative;

                .options {
                    padding: 10px;
                    gap: 5px;

                    z-index: 1;

                    background-color: white;

                    &.hidden {
                        display: none;
                    }

                    position: absolute;

                    top: -10px;
                    right: -10px;
                    width: max-content;
                    border-radius: 10px;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                }
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .course {
            border-radius: 20px;
            min-height: 246px;
            overflow: hidden;

            &.loading {
                &::before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 1;

                    animation: shimmer 1s forwards infinite linear;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(211 211 211 / 60%) 50%, rgba(255, 255, 255, 0) 100%);
                }

                //height: 100px;
                position: relative;
            }

            .inner {
                cursor: pointer;

                padding: 22px 28px 22px 20px;

                position: relative;
                z-index: 0;
                transition: background-color 1s ease-in-out;

                display: flex;
                flex-direction: column;
                gap: 27px;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #000;

                height: max-content;

                .upper {
                    display: flex;
                    flex-direction: row;
                    gap: 40px;

                    .image {
                        width: 359px;
                        height: 202px;
                        border-radius: 15px;
                        object-fit: cover;
                    }

                    .details {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;

                        .title {
                            font-family: Manrope, sans-serif;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 33px;
                            display: flex;
                            align-items: center;
                            letter-spacing: -0.1px;

                            color: #ffffff;

                            span {
                                font-size: 14px;
                            }
                        }

                        .bottom {
                            display: flex;
                            justify-content: space-between;
                            flex-grow: 1;

                            .left {
                                .publisherChip {
                                    flex-shrink: 0;
                                    margin-top: 7px;
                                    margin-bottom: 20px;

                                    background: white;
                                    border-radius: 10px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    gap: 6px;
                                    padding: 3px 6px 4px 6px;

                                    width: min-content;

                                    .publisherImage {
                                        width: 17px;
                                        height: 17px;
                                    }

                                    .publisherName {
                                        font-family: Manrope, sans-serif;
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 10px;
                                        line-height: 14px;
                                        display: flex;
                                        align-items: center;
                                        text-align: center;
                                        letter-spacing: -0.1px;

                                        color: #2c2b5e;
                                    }
                                }

                                .description {
                                    font-family: Manrope, sans-serif;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 19px;
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: -0.1px;

                                    /* RetainIt Blue */

                                    color: #2c2b5e;
                                }
                            }

                            .right {
                                margin-top: auto;

                                display: flex;
                                flex-direction: column;
                                gap: 7px;
                                align-items: center;

                                font-family: Manrope;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                letter-spacing: -0.1px;

                                color: #ffffff;

                                .progressIndicator {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    position: relative;
                                    width: 90px;
                                    height: 90px;

                                    svg {
                                        position: absolute;
                                    }
                                }
                            }
                        }
                    }
                }

                .blocks {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    gap: 14px;

                    .block {
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;

                        gap: 10px;

                        &.complete {
                            color: #fff597;
                        }

                        color: #2c2b5e;

                        font-family: "Manrope", sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 25px;
                        letter-spacing: -0.1px;

                        .description {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                        }

                        .progressBar {
                            height: 2px;
                            width: 100%;
                            background: #2c2b5e;
                            position: relative;

                            .fill {
                                position: absolute;
                                z-index: 1;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                background: #fff597;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
