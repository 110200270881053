.backdrop {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .reminder-container {
        background: #ffffff;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        width: 100%;
        padding: 24px 16px 16px 16px;
        position: relative;
        .close-btn {
            text-align: center;
            font-size: 10px;
            line-height: 20px;
            position: absolute;
            top: 30px;
            right: 40px;
            // border-radius: 50%;
            color: rgba(0, 0, 0, 0.616);
            // background: #cfc8c875;
            cursor: pointer;
            height: 20px;
            width: 20px;
        }
        .reminder-instructions {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-bottom: 24px;
        }
        .predefined-dates {
            .title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */
                letter-spacing: 0.02em;
                color: #000000;
                margin-bottom: 8px;
            }
            .predefined-dates-list {
                overflow-x: auto;
                overflow-y: hidden;
                max-height: 70px;
                overflow-y: hidden;
                overflow-x: auto;
                height: 70px;
                display: block;
                white-space: nowrap;
                .predef-date {
                    width: 132px;
                    height: 60px;
                    margin-right: 8px;
                    display: inline-block;
                    text-align: center;
                    border: 1px solid #eeeeee;
                    border-radius: 4px;
                    padding-top: 13px;
                    cursor: pointer;
                    &.selected {
                        border: 2px solid #356dff;
                        border-radius: 4px;
                    }
                    .predef-date-text {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                    }
                    .predef-date-value {
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 15px;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }
}
.calendar-title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 20px;
}

.calendar {
    .calendar-header {
        display: flex;
        align-items: center;
        justify-content: center;
        .dropdown {
            margin: 5px;
            button {
                background-color: white;
                color: #19181a;
                border: 0;
                border-radius: 8px;
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                &:focus {
                    box-shadow: unset !important;
                    background-color: #f8f7fa;
                    color: #356dff;
                }
            }
            .dropdown-menu {
                max-height: 250px;
                overflow-y: auto;
                min-width: 70px;
                border-radius: 8px;
                box-shadow: 0 30px 84px rgba(19, 10, 46, 0.08), 0 8px 32px rgba(19, 10, 46, 0.07), 0 3px 14px rgba(19, 10, 46, 0.03),
                    0 1px 3px rgba(19, 10, 46, 0.13);
                // a {
                //     width: 50px;
                // }
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        width: 167px;
        height: 40px;
        border-radius: 8px;
        font-family: Manrope, sans-serif;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #19181a;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.cancel {
            background-color: #f8f7fa;
            color: #19181a;
        }
        &.confirm {
            background-color: #356dff;
            color: white;
        }
    }
}
@media only screen and (min-width: 992px) {
    .backdrop {
        .reminder-container {
            width: 591px;
            height: 512px;
            padding: 30px 40px;
            .date-picker {
                display: flex;
                flex-direction: row-reverse;
                .predefined-dates {
                    margin-left: 80px;
                    .title {
                        margin-bottom: 16px;
                    }
                    .predefined-dates-list {
                        display: flex;
                        flex-direction: column;
                        max-height: 250px;
                        height: 250px;
                        .predef-date {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
    .reminder-instructions {
        margin-bottom: 40px;
    }
    .calendar {
        max-width: 300px;
        .calendar-body {
            height: 270px;
        }
    }
    .actions {
        justify-content: left;
        margin-top: 40px;
        .btn {
            margin-right: 8px;
            width: 110px;
        }
    }
    .calendar-title {
        display: none;
    }
}

// .reminder {
//     position: absolute;
//     top: 50px;
//     left: 0;
//     width: 100%;
//     background: linear-gradient(180deg, #2b63e7 0%, #1c4fcf 100%);
//     z-index: 2023;
// }
// .prede-time {
//     color: black;
//font-family: Manrope, sans-serif; //     font-style: normal;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 18px;
//     padding: 20px 20px 0 20px;
//     .btn {
//         margin-bottom: 20px;
//         cursor: pointer;
//     }
// }
// .custom-time {
//     color: black;
//font-family: Manrope, sans-serif; //     font-style: normal;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 18px;
//     padding: 0 20px 20px 20px;
//     cursor: pointer;
//     &.calendar-visible {
//         margin-top: 20px;
//         &::before {
//             content: "< ";
//         }
//         .btn {
//             display: inline-block;
//             cursor: pointer;
//         }
//     }
// }
// .calendar {
//     color: black;
//font-family: Manrope, sans-serif; //     font-style: normal;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 18px;
//     padding-top: 8px;
//     max-width: 150px;
//     width: 150px;
//     .form-element {
//         margin-bottom: 5px;
//         .DT-input {
//             width: 140px;
//             font-size: 10px;
//             letter-spacing: -1px;
//font-family: Manrope, sans-serif; //         }
//     }
// }
// .message {
//     color: black;
//font-family: Manrope, sans-serif; //     font-style: normal;
//     font-weight: 600;
//     font-size: 15px;
//     line-height: 18px;
//     padding: 20px;
// }
