.carousel-wrapper {
    display: flex;
    align-items: center;

    .arrow {
        width: 14px;
        height: 24px;
        margin: 0 10px 40px;
        cursor: pointer;
        opacity: 0.25;
        img {
            width: 14px;
            height: 24px;
        }
    }

    .prev-arrow {
        width: 14px;
    }

    .next-arrow {
        img {
            transform: rotate(180deg);
        }
    }
}

.carouselItem {
    background: rgba(18, 31, 51, 1);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    padding-bottom: 20px;
    width: 270px;

    .video {
        position: relative;
        width: 270px;
        height: 130px;
        overflow: hidden;
        border-radius: 24px 24px 0 0;

        iframe {
            border-radius: 24px 24px 0 0;
        }

        .thumbnail {
            width: 270px;
            height: 180px;
            border-radius: 24px 24px 0 0;
            margin-top: -25px;
        }

        .video-overlay {
            position: absolute;
            top: 0;
            right: 0;
            width: 270px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 24px 24px 0 0;

            img {
                height: 37px;
                width: 37px;
            }

            .video-length {
                bottom: 0;
                position: absolute;
                right: 0;
                background: #ffffffcc;
                font-size: 12px;
                padding: 1px 5px;
                border-radius: 6px 0 0 0;
            }
        }
    }

    .title {
        padding: 20px 20px;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        font-family: Manrope, sans-serif;
        cursor: pointer;
    }

    .publisher {
        padding: 12px 16px;
        cursor: pointer;

        img {
            height: 28px;
            width: auto;
            margin: 0 10px;
        }

        .pub-name {
            color: white;
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
        }
    }
}

.launcher-container {
    margin-left: 15px;

    #launcher {
        background: linear-gradient(180deg, #4a96ff 0%, #326cf2 45.83%, #1c4fcf 100%);
        box-shadow: 0px 3px 3px rgba(53, 109, 255, 0.2), 0px 5px 8px rgba(73, 109, 219, 0.1);
        border-radius: 4%;
        width: 142px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        cursor: pointer;

        img {
            height: 38px;
            width: auto;
            border-radius: 50%;
            margin-left: -15px;
        }

        .btn-text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 13px;
            line-height: 18px;
            color: #ffffff;
            margin-left: 9px;
        }
    }
}

.bordered {
    height: 270px;
    width: auto;
    align-items: center;
    background-color: white;
    padding: 24px 27px 32px 35px;
    border-radius: 30px;

    &.align-left {
        padding-left: 0;
    }

    .carousel-header {
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: #130b33;
        margin-bottom: 21px;
        margin-left: 5px;
    }
}

.light-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 210px;
    cursor: pointer;

    &.large {
        width: 268px;

        .image,
        .article {
            width: 268px;
            height: 150px;
        }
    }

    .image,
    .article {
        width: 210px;
        height: 118px; // calculated from aspect ratio of youtube thumbnails
    }

    .article {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));

        border-radius: 10px;
        flex-shrink: 0;

        background: #d9d9d9;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: cover;
            width: 36px;
            height: 36px;
        }
    }

    .articleImage,
    .image {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));
        flex-shrink: 0;
        background: #c4c4c4;
        box-shadow: 0px 1px 4px rgba(53, 109, 255, 0.05);
        border-radius: 10px;
        position: relative;

        img {
            border-radius: 10px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .corner {
            position: absolute;
            bottom: 0;
            right: 86px;
            width: 30px;
            height: 26px;
            background-color: rgba(25, 28, 34, 045.83%);

            &::before {
                content: "";
                position: absolute;
                width: 200%;
                height: 200%;
                box-shadow: 50px 50px 0 0 rgba(25, 28, 34, 045.83%);
                bottom: -5px;
                right: -5px;
            }
        }

        .timing {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 82px;
            height: 27px;
            padding-left: 17px;
            color: white;
            font-size: 12px;
            line-height: 15px;
            font-weight: 500;
            font-family: Manrope, sans-serif;
            background: rgba(25, 28, 34, 045.83%);
            border-radius: 30px 0 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            // &::before{
            //     width: 0;
            //     height: 0;
            //     line-height: 0;
            //     border-top: 10px solid transparent;
            //     border-left: 10px solid transparent;
            //     border-right: 10px solid rgba(25,  28, 34, 045.83%);
            //     border-bottom: 10px solid rgba(25,  28, 34, 045.83%);
            //     bottom: 0;
            //     left: -16px;
            //     position: absolute;
            //     content: "";
            //     display: block;
            // }
        }
    }

    .title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 13px;

        .text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}
