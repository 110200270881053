@import "~bootstrap/scss/bootstrap";
@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter/Inter-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter/Inter-Light.ttf);
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter/Inter-Medium.ttf);
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter/Inter-SemiBold.ttf);
    font-display: swap;
    font-weight: 600;
}

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter/Inter-Bold.ttf);
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf);
    font-display: swap;
    font-weight: 800;
}

@font-face {
    font-family: Manrope;
    src: url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf) format("truetype-variations");
    font-weight: 1 999;
}

@font-face {
    font-family: "Lexend Deca";
    src: url(./assets/fonts/Lexend_Deca/LexendDeca-VariableFont_wght.ttf) format("truetype-variations");
    font-weight: 1 999;
}

//@font-face {
//    font-family: Manrope;
//    src: url(./assets/fonts/Manrope/static/Manrope-Regular.ttf);
//    font-display: swap;
//}
//
//@font-face {
//    font-family: Manrope;
//    src: url(./assets/fonts/Manrope/static/Manrope-Light.ttf);
//    font-display: swap;
//    font-weight: 300;
//}
//
//@font-face {
//    font-family: Manrope;
//    src: url(./assets/fonts/Manrope/static/Manrope-Medium.ttf);
//    font-display: swap;
//    font-weight: 500;
//}
//
//@font-face {
//    font-family: Manrope;
//    src: url(./assets/fonts/Manrope/static/Manrope-SemiBold.ttf);
//    font-display: swap;
//    font-weight: 600;
//}
//
//@font-face {
//    font-family: Manrope;
//    src: url(./assets/fonts/Manrope/static/Manrope-Bold.ttf);
//    font-display: swap;
//    font-weight: 700;
//}
//
//@font-face {
//    font-family: Manrope;
//    src: url(./assets/fonts/Manrope/static/Manrope-ExtraBold.ttf);
//    font-display: swap;
//    font-weight: 800;
//}

@font-face {
    font-family: Indie Flower;
    src: url(./assets/fonts/IndieFlower-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: Lato;
    src: url(./assets/fonts/Lato-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: "Droid Sans";
    src: url(https://storage.googleapis.com/staging.retainit.appspot.com/demo-widget/DroidSans-webfont.woff) format("woff");
    font-display: swap;
}

html {
    height: -webkit-fill-available;
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: -webkit-fill-available;
    height: 100%;
    #retainit-app {
        height: -webkit-fill-available;
        height: 100%;
        .swiper-button-disabled {
            opacity: 0;
        }
        .carousel-inner {
            // overflow: unset;
            //  max-width: 338px;
            //  width: 338px;
        }
        .carousel-indicators {
            //background-color: pink;
            position: absolute;
            top: -40px;
            justify-content: flex-start;
            bottom: unset;
            margin: 0;
            //  & [data-bs-target]{
            // background-color: rgba(73, 109, 219, 0.1);
            // border-radius: 4px;
            [type="button"] {
                background-color: rgba(73, 109, 219, 0.1);
                border-radius: 4px;
                width: -webkit-fill-available;
                width: 100%;
                &.active {
                    background-color: rgba(47, 104, 240, 1);
                }
            }
            // }
        }
        .carousel-inner .carousel-item-end.active,
        .carousel-inner .carousel-item-next {
            transform: translateX(66.5%);
        }
        .carousel-inner .carousel-item-start.active,
        .carousel-inner .carousel-item-prev {
            transform: translateX(-66.5%);
        }
        .carousel-inner .carousel-item-end,
        .carousel-inner .carousel-item-start {
            transform: translateX(0);
        }
    }
}

.carousel-inner .carousel-item > div {
    display: none;
}

.carousel-inner .carousel-item > div:first-child {
    display: block;
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

.carousel-indicators,
.carousel-indicators li {
    cursor: default;
    pointer-events: none;
}

//   .carousel-inner .carousel-item-right.active,
// .carousel-inner .carousel-item-next {
//   transform: translateX(33.33%);
// }
// .carousel-inner .carousel-item-left.active,
// .carousel-inner .carousel-item-prev {
//   transform: translateX(-33.33%)
// }
// .carousel-inner .carousel-item-right,
// .carousel-inner .carousel-item-left{
//   transform: translateX(0);
// }
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    text-decoration: none !important;
}

.card-footer {
    margin-bottom: 0;
}

.swiper {
    // Remove this if you don't want to include custom navigation arrows.
    width: 100%;
    margin-left: 0 !important;
}

.swiper-button-lock {
    display: block !important;
}

@media only screen and (min-width: 992px) {
    body {
        .swiper {
            // Remove this if you don't want to include custom navigation arrows.
            width: calc(100% - 70px);
            margin-left: 0 !important;
        }
        #retainit-app {
            .carousel-inner .carousel-item-end.active,
            .carousel-inner .carousel-item-next {
                transform: translateX(41%);
            }
            .carousel-inner .carousel-item-start.active,
            .carousel-inner .carousel-item-prev {
                transform: translateX(-41%);
            }
            .carousel-inner .carousel-item-end,
            .carousel-inner .carousel-item-start {
                transform: translateX(0);
            }
            .carousel-indicators {
                [type="button"] {
                    // width: inherit;
                }
            }
        }
    }
}
