.userProfilePic {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3f51b5;
    color: #fff;
}
