.container {
    min-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    .leftContainer,
    .rightContainer {
        max-height: 100vh;
        z-index: 1;

        flex-grow: 0;
        flex-shrink: 0;

        position: sticky;
        top: 0;
    }

    .rightContainer {
        width: 298px;
        background: linear-gradient(171.08deg, #2c2b5e 17.38%, #435bc9 76.34%);
    }

    .inner {
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 100%;
    }
}

.left {
    height: 25%;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        margin-top: 7.5vh;
    }

    .content {
        color: #1e1e1e;
        padding-top: 56px;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 33px;
    }
}

.right {
    height: 75%;
    min-height: 320px;
    background: linear-gradient(30.79deg, #356dff 0%, #2355d5 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .sally-left {
        display: block;
        width: 75%;
        margin-top: 10%;
    }

    .sally-right {
        display: none;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    height: 51px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding-left: 7vw;
    color: white;
    text-align: center;
}

.onboarding {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .onb-card {
        // height: 550px;
        width: 370px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        .message-container {
            margin: 32px 20px 15px;

            .greeting {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 22px;
                /* or 92% */
                letter-spacing: -0.408px;
                color: #000000;
                margin-bottom: 20px;
            }

            .message {
                font-weight: 400;
                font-size: 16px;

                div {
                    margin-bottom: 15px;
                }
            }
        }

        .onb-close {
            position: absolute;
            right: 30px;
            top: 20px;
            font-size: 10px;
            cursor: pointer;
        }

        .btn {
            background: #356dff;
            border-radius: 8px;
            width: 229px;
            height: 40px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 25px 0 32px;
            cursor: pointer;
        }
    }
}

input[type="checkbox"] {
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .content {
        margin-bottom: 0;
        padding: 0;
        max-width: 100%;
        width: 100%;
    }
    .squeezed {
        // margin-left: 242px;
        margin-left: 248px;
    }
    .onboarding {
        .onb-card {
            width: 591px;

            .message-container {
                width: 470px;
                margin: 32px 0 15px 0;
            }

            .onb-close {
                right: 40px;
                top: 40px;
            }
        }
    }
}
