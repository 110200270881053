.carousel-wrapper {
    display: flex;
    align-items: center;

    .arrow {
        width: 14px;
        height: 24px;
        margin: 0 10px 40px;
        cursor: pointer;
        opacity: 0.25;

        img {
            width: 14px;
            height: 24px;
        }
    }

    .prev-arrow {
        width: 14px;
    }

    .next-arrow {
        img {
            transform: rotate(180deg);
        }
    }
}

.carouselItem {
    background: rgba(18, 31, 51, 1);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    padding-bottom: 20px;
    width: 270px;

    .video {
        position: relative;
        width: 270px;
        height: 130px;
        overflow: hidden;
        border-radius: 24px 24px 0 0;

        iframe {
            border-radius: 24px 24px 0 0;
        }

        .thumbnail {
            width: 270px;
            height: 180px;
            border-radius: 24px 24px 0 0;
            margin-top: -25px;
        }

        .video-overlay {
            position: absolute;
            top: 0;
            right: 0;
            width: 270px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 24px 24px 0 0;

            img {
                height: 37px;
                width: 37px;
            }

            .video-length {
                bottom: 0;
                position: absolute;
                right: 0;
                background: #ffffffcc;
                font-size: 12px;
                padding: 1px 5px;
                border-radius: 6px 0 0 0;
            }
        }
    }

    .title {
        padding: 20px 20px;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        font-family: Manrope, sans-serif;
        cursor: pointer;
    }

    .publisher {
        padding: 12px 16px;
        cursor: pointer;

        img {
            height: 28px;
            width: auto;
            margin: 0 10px;
        }

        .pub-name {
            color: white;
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
        }
    }
}

.launcher-container {
    margin-left: 15px;

    #launcher {
        background: linear-gradient(180deg, #4a96ff 0%, #326cf2 45.83%, #1c4fcf 100%);
        box-shadow: 0px 3px 3px rgba(53, 109, 255, 0.2), 0px 5px 8px rgba(73, 109, 219, 0.1);
        border-radius: 4%;
        width: 142px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        cursor: pointer;

        img {
            height: 38px;
            width: auto;
            border-radius: 50%;
            margin-left: -15px;
        }

        .btn-text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 13px;
            line-height: 18px;
            color: #ffffff;
            margin-left: 9px;
        }
    }
}

.bordered {
    height: 270px;
    width: auto;
    align-items: center;
    background-color: white;
    padding: 24px 27px 32px 35px;
    border-radius: 30px;

    &.align-left {
        padding-left: 0;
    }

    .carousel-header {
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: #130b33;
        margin-bottom: 21px;
        margin-left: 5px;
    }
}

.listCard {
    display: flex;
    flex-direction: row;

    align-items: center;
    //justify-content: center;
    cursor: pointer;

    gap: 20px;

    padding: 8px 9px 5.5px 24px;
    border-radius: 15px;

    &.selected {
        margin-top: -8px;
        padding-top: 8px;
        margin-bottom: -8px;
        padding-bottom: 8px;
        margin-left: -10px;
        padding-left: 30px;

        //margin-left: ;
        background-color: #f2f2f2;
    }

    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    .title {
        flex-grow: 1;
    }

    .reviewData {
        flex-shrink: 0;
        color: #ffffff;

        .comingUp {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.1px;
        }
        .notIn {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.1px;
        }
    }

    .image {
        object-fit: cover;
        border-radius: 10px;
        width: 48px;
        height: 48px;
    }
}
