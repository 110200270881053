.outer {
    display: flex;
    flex-direction: column;

    min-height: 100vh;

    background: radial-gradient(50% 50% at 50% 50%, #181a60 0%, #010d38 100%);

    overflow: hidden;

    .body {
        .left {
            display: flex;
            flex-direction: column;
            //align-items: self-start;
        }
        .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 600px;

            flex-grow: 1;
            position: relative;

            @media only screen and (max-width: 992px) {
                min-width: unset;
                width: 100%;
            }

            .bottomImage {
                position: absolute;
                //top: -427px;
                //margin-bottom: -427px;
                //margin: -65% -60% -45%;

                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;

                z-index: 10;

                @media only screen and (max-width: 992px) {
                    max-width: 135%;
                    margin: -24% 0 -200px;
                    transform: translateY(-18%);
                    left: unset;
                    top: unset;
                }

                .gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
                }
            }
        }

        overflow: visible;

        z-index: 1;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        flex-grow: 1;

        padding: 60px 100px;

        font-family: "Lexend Deca", sans-serif;
        font-style: normal;
        font-weight: 400;

        @media only screen and (max-width: 992px) {
            padding: 40px 10px 50px;

            flex-direction: column;

            gap: 20px;
        }

        .retainitLogoLarge {
            height: 32px;
            margin-bottom: 12px;
        }

        h1 {
            max-width: 630px;
            font-size: 52px;
            line-height: 65px;

            color: white;

            margin-bottom: 19px;

            @media only screen and (max-width: 992px) {
                font-size: 32px;
                line-height: 40px;

                max-width: 313px;

                margin-bottom: 14px;

                text-align: center;
            }
        }

        h2 {
            max-width: 630px;

            font-size: 14px;
            line-height: 24px;
            /* or 171% */

            margin-bottom: 19px;

            color: #626a9d;

            @media only screen and (max-width: 992px) {
                font-size: 14px;
                line-height: 24px;
                padding: 0 20px;

                max-width: 313px;
                margin-bottom: 14px;

                text-align: center;
            }
        }

        .signup {
            background: #5ac7a8;
            border-radius: 20px;

            padding: 16px 27px;

            width: max-content;

            font-family: "Manrope", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #2c2b5e;

            margin-top: 40px;

            cursor: pointer;
            @media only screen and (max-width: 992px) {
                padding: 16px 25px;
                font-size: 16px;
            }
        }
    }

    .icons {
        margin-bottom: 58px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 36px;
        @media only screen and (max-width: 992px) {
            margin-bottom: 28px;
        }
    }

    .getInTouch {
        //z-index: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: start;
        @media only screen and (max-width: 992px) {
            align-items: center;
        }

        a {
            position: relative;
            z-index: 2;

            width: 203px;
            height: 51px;
            border-radius: 50px;

            background: #ffffff;

            font-family: "Lexend Deca", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 37px;

            color: #091245;

            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
        }

        .ellipses {
            position: absolute;
            z-index: 1;

            left: 50%;
            //transform: translateX();

            overflow: visible;
            bottom: 0;
            margin-bottom: -39px;

            @media only screen and (max-width: 992px) {
                margin-bottom: -210px;
                transform: translateX(-50%) scale(0.5);
            }
        }
    }
}

.header {
    top: 0;
    //position: sticky;

    //height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    //border-bottom: 1px solid #181818;
    //background: white;
    //background: #1f2023;

    padding: 40px 30px 0;

    @media only screen and (max-width: 992px) {
        padding: 22px 15px 0 15px;
    }

    .retainitLogo {
        height: 21px;
    }

    .middleButtonsContainer {
        flex-grow: 1;
        margin: 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: center;

        .button {
            flex-grow: 0;

            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;

            //color: #444;
            color: #aaa;

            cursor: pointer;
        }
    }

    .rightButtonsContainer {
        //background-color: #ffffff;
        margin-left: -66.8px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .loginButton:hover {
        opacity: 1;
    }
    .loginButton {
        padding: 6.5px 14px;

        opacity: 0;

        background: white;
        border-radius: 4px;

        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: #2c2b5e;

        cursor: pointer;
    }
}

.footer {
    //bottom: 0;
    //position: sticky;

    margin-top: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;

    padding: 0 50px 18px;

    @media only screen and (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 15px;
    }

    font-family: "Lexend Deca", sans-serif;
    font-style: normal;
    font-weight: 400;
    align-items: center;

    text-align: center;

    .left {
        font-size: 12px;
        line-height: 140%;
        /* identical to box height, or 17px */

        display: flex;
        align-items: flex-end;

        color: #ffffff;
        @media only screen and (max-width: 992px) {
            color: rgba(255, 255, 255, 0.31);
        }
    }

    .right {
        font-size: 14px;
        line-height: 144%;
        /* or 20px */
        color: rgba(255, 255, 255, 0.8);
        @media only screen and (max-width: 992px) {
            color: rgba(255, 255, 255, 0.31);
        }
    }
}
