.page {
    width: 100%;
    height: calc(100% - 85px);
    margin: 0 auto 85px;
    .header {
        padding: 10px;
    }
    .container {
        padding: 10px;
    }
}

.card-item {
    display: inline-block;
    margin: 10px;
    .logo {
        width: 100px;
        height: auto;
        margin: 0 auto;
        cursor: pointer;
    }
    .title {
        font-family: Manrope, sans-serif;
        text-align: center;
    }
    .follow {
        background-color: rgb(6, 88, 143);
        color: white;
        font-weight: 600;
        border: 1px solid rgb(4, 74, 121);
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        &.following {
            background-color: white;
            color: rgb(6, 88, 143);
            font-weight: 400;
            border: 1px solid rgb(53, 53, 53);
            border-radius: 20px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .page {
        margin: 0 auto;
        height: unset;
    }
}
