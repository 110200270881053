.outer {
    display: flex;
    flex-direction: column;
    gap: 44px;
    align-items: center;

    .inner {
        display: flex;
        flex-direction: row;
        gap: 26px;

        border-radius: 20px;
        padding: 18px 12px 22px 18px;

        .image {
            width: 190px;
            height: 190px;
            border-radius: 10px;

            object-fit: cover;
        }

        .description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            .title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 38px;
                display: flex;
                align-items: center;
                letter-spacing: -0.1px;

                color: #ffffff;
            }

            .publisherChip {
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                background-color: white;
                border-radius: 10px;
                padding: 5px 8px;

                height: 26px;

                gap: 8px;

                .image {
                    height: 16px;
                    width: 16px;
                    border-radius: 4px;
                }

                .name {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.1px;

                    color: #2c2b5e;
                }
            }

            .bottom {
                margin-top: auto;

                .lastReviewed {
                    span {
                    }
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        gap: 51px;

        .doLater,
        .reivew {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .top {
                width: 75px;
                height: 75px;
                border-radius: 75px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .bottom {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.1px;

                color: #2c2b5e;
            }
        }

        .doLater .top {
            background: #2c2b5e;
        }

        .reivew .top {
            background: #5ac7a8;
        }
    }

    .review-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .review-image {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #d9d9d9;
            height: fit-content;

            img {
                width: 100%;
            }

            iframe {
                width: 745px;
                height: 388px;
            }

            .articleImage {
                cursor: pointer;

                //border-top-left-radius: 6px;
                //border-top-right-radius: 6px;
            }

            .article-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                width: 100%;

                > img {
                    width: 66px;
                    height: 66px;
                }

                gap: 56px;

                height: 380px;

                > img {
                    width: 140px;
                    height: 140px;
                }
            }

            .article-button {
                width: 210px;
                height: 56px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: relative;

                cursor: pointer;

                background: #ffffff;
                border: 0.5px solid #dedede;
                /* shadow 1 */

                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07), 0 14px 24px rgba(0, 0, 0, 0.05);
                border-radius: 12px;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                /* identical to box height */

                letter-spacing: -0.01em;

                color: #356dff;

                img {
                    color: #356dff;
                    width: 24px;
                    height: 24px;

                    position: absolute;
                    top: 17px;
                    right: 8px;
                }
            }
        }

        .review-actions {
            margin-top: 40px;
            display: flex;
            align-items: flex-end;
            align-content: flex-end;
            justify-content: center;

            .action-group {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 20px;
                cursor: pointer;

                .review-action {
                    width: 54px;
                    height: 54px;
                    background: #ffffff;
                    border-radius: 100px;
                    filter: drop-shadow(0px 12px 44px rgba(0, 0, 0, 0.15));
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.main {
                        background: #356dff;
                        width: 61px;
                        height: 61px;
                    }
                }

                .review-action-text {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    color: #356dff;
                    margin-top: 14px;
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .reviewCard {
    }
}
