.inner {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding-left: 60px;
    padding-right: 66px;
    padding-bottom: 60px;

    .top {
        margin-top: 65px;
        margin-bottom: 28px;

        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 28px;

        font-family: "Manrope", sans-serif;
        font-style: normal;
        letter-spacing: -0.1px;
        color: #2c2b5e;

        .title {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;

            flex-grow: 1;
        }

        .searchContainer {
            position: relative;

            flex-basis: 225px;
            flex-grow: 0;
            flex-shrink: 1;

            height: 36px;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 18px;
                padding: 5px 32px 7px 12px;
                border: 1px solid #2c2b5e;

                color: #2c2b5e;

                &::placeholder {
                    color: #939395;
                }
            }

            img {
                width: 20px;
                height: 20px;
                z-index: 1;

                margin-right: 12px;
            }
        }

        .sort {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            /* identical to box height */

            color: #2c2b5e;

            display: flex;
            flex-direction: row;
            gap: 10px;

            .arrow {
                cursor: pointer;
                transition: transform 0.2s ease-in-out;

                &.flipped {
                    transform: rotate(180deg);
                }
            }

            .current {
                cursor: pointer;

                position: relative;

                .options {
                    padding: 10px;
                    gap: 5px;

                    z-index: 1;

                    background-color: white;

                    &.hidden {
                        display: none;
                    }

                    position: absolute;

                    top: -10px;
                    right: -10px;
                    width: max-content;
                    border-radius: 10px;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                }
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        gap: 16px;

        .badge {
            display: flex;
            flex-direction: row;
            gap: 14px;

            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #000;

            padding: 7px 12px 10px 16px;

            border-radius: 20px;
            border: 5px solid transparent;

            .icon {
                width: 45px;
                height: 45px;
                border-radius: 6px;
            }

            .content {
                flex-grow: 1;

                .name {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-weight: 700;

                    span {
                        font-size: 14px;
                    }

                    .pin {
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }

                .progressBarOuter {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    gap: 12px;

                    .progressBar {
                        flex-grow: 1;

                        border: 2px solid;
                        padding: 2px;
                        border-radius: 10px;

                        position: relative;

                        .progressBarInner {
                            position: relative;

                            top: 0;
                            bottom: 0;
                            left: 0;

                            height: 5px;
                            border-radius: 3px;
                        }
                    }

                    .text {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 25px;

                        min-width: 42px;

                        span {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}
