.inner {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding-left: 60px;
    padding-right: 66px;
    padding-bottom: 60px;

    .top {
        margin-top: 65px;
        margin-bottom: 28px;
        margin-left: 67px;

        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 28px;

        font-family: "Manrope", sans-serif;
        font-style: normal;
        letter-spacing: -0.1px;
        color: #2c2b5e;

        .title {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;

            flex-grow: 1;
        }

        .searchContainer {
            position: relative;

            flex-basis: 225px;
            flex-grow: 0;
            flex-shrink: 1;

            height: 36px;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            input {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 18px;
                padding: 5px 32px 7px 12px;
                border: 1px solid #2c2b5e;

                color: #2c2b5e;

                &::placeholder {
                    color: #939395;
                }
            }

            img {
                width: 20px;
                height: 20px;
                z-index: 1;

                margin-right: 12px;
            }
        }

        .sort {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            /* identical to box height */

            color: #2c2b5e;

            display: flex;
            flex-direction: row;
            gap: 10px;

            .arrow {
                cursor: pointer;
                transition: transform 0.2s ease-in-out;

                &.flipped {
                    transform: rotate(180deg);
                }
            }

            .current {
                cursor: pointer;

                position: relative;

                .options {
                    padding: 10px;
                    gap: 5px;

                    z-index: 1;

                    background-color: white;

                    &.hidden {
                        display: none;
                    }

                    position: absolute;

                    top: -10px;
                    right: -10px;
                    width: max-content;
                    border-radius: 10px;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                }
            }
        }
    }

    .items {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        gap: 18px;

        .itemGroup {
            display: flex;
            flex-direction: row;

            .left {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;

                text-align: right;
                letter-spacing: -0.1px;

                color: #5f6368;

                margin-top: 17px;
                margin-right: 23px;
                width: 72px;
                flex-shrink: 0;
                flex-grow: 0;
            }

            .right {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: 18px;
            }
        }
    }

    .empty {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 130%;
            /* or 18px */

            text-align: center;

            color: #000000;
        }

        .welcomeTitle {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 130%;
            /* or 18px */

            text-align: center;
            margin-bottom: 30px;
            color: #000000;
        }
    }
}
