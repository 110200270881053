.container {
    min-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: stretch;

    background: linear-gradient(272.03deg, #2c2b5e -1.1%, #435bc9 119.83%);

    font-family: Manrope, sans-serif;
    font-style: normal;

    .inner {
        &.verify {
            padding-top: 20px;
        }

        overflow: hidden;

        padding: 100px 20px 20px;

        @media only screen and (max-width: 992px) {
            padding-top: 20px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        flex-grow: 1;

        .title {
            margin-bottom: 20px;

            font-weight: 700;
            font-size: 50px;
            line-height: 68px;
            text-align: center;

            color: #5ac7a8;

            transition: font-weight 0.5s;

            @media only screen and (max-width: 992px) {
                font-size: 30px;
                line-height: 41px;
            }

            &:hover {
                font-weight: 800;
            }
        }

        .subtitle {
            margin-bottom: 20px;

            max-width: 930px;

            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            text-align: center;

            color: #ffffff;

            transition: font-weight 0.5s;

            &:hover {
                font-weight: 800;
            }

            @media only screen and (max-width: 992px) {
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 0px;
            }
        }

        .logosCotainer {
            margin-top: 40px;
            margin-bottom: 40px;

            display: flex;
            flex-direction: row;
            align-items: center;

            gap: 18px;

            .otherLogo,
            .retainitLogo {
                scale: 1;
                transition: scale 1s;

                &:hover {
                    scale: 1.1;
                }
            }

            .retainitLogo {
                height: 21px;
            }

            .plus {
                height: 22px;
            }

            .otherLogo {
                height: 32px;
            }
        }

        .content {
            max-width: 100%;

            position: relative;
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            align-content: center;

            .gif {
                max-width: 100%;
                margin: 0 auto;
            }

            .centre {
                height: 75%;
            }

            form {
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
            }

            .horizontalForm {
                position: relative;

                flex-direction: row;
                justify-content: center;
                align-items: center;

                gap: 40px;

                .hidden {
                    visibility: hidden;
                }

                .spinner {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                }

                @media only screen and (max-width: 992px) {
                    flex-direction: column;
                }

                input {
                    @media only screen and (max-width: 992px) {
                        min-width: 260px;
                        margin-top: 20px;
                    }

                    min-width: 384px;
                }

                button[type="submit"] {
                    position: relative;
                    margin-top: 0;
                }
            }

            :not(.horizontalForm) {
                button[type="submit"] {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .no-account {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;

                // display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                color: white;
                text-align: center;
                //margin-top: 30px;
            }

            .with-google {
                margin: 30px auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .wrapper {
                    &.disabled {
                        opacity: 0.7;

                        #signin {
                            cursor: pointer;
                            pointer-events: none;
                        }

                        &:hover .tooltip {
                            display: block;
                        }
                    }
                }

                #signin {
                    margin-top: 25px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .tooltip {
                    display: none;
                }
            }

            .or {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                margin-top: 30px;

                span {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    /* identical to box height */
                    text-align: center;
                    text-transform: uppercase;
                    color: #000000;
                }

                hr {
                    display: inline-block;
                    margin-top: 0.5em;
                    margin-bottom: 0.5em;
                    margin-left: auto;
                    margin-right: auto;
                    border-style: inset;
                    border-width: 1px;
                    width: calc(50% - 20px);

                    &.line-left {
                        margin-right: 10px;
                    }

                    &.line-right {
                        margin-left: 10px;
                    }
                }
            }

            .instructions {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 300;
                max-width: 800px;
                font-size: 24px;
                line-height: 150%;
                /* or 27px */
                //color: #000000;
                color: white;
                margin: 16px 0 27px 0;

                a {
                    color: #5ac7a8;
                }
            }

            .go-back {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                /* identical to box height */

                margin-top: 40px;
                text-align: center;
                cursor: pointer;
                color: white;
            }

            .title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 39px;
                color: #1e1e1e;
                margin-bottom: 25px;
            }

            label {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 500;
                // font-size: 16px;
                // line-height: 19px;
                //color: #000000;
                color: white;
            }

            input {
                &[type="text"],
                &[type="password"],
                &[type="email"] {
                    background: #2c2b5e;
                    border: 2px solid #5ac7a8;
                    border-radius: 10px;

                    color: white;
                    box-sizing: border-box;

                    &.invalid {
                        border-color: #f15530;
                    }
                }
            }

            .errorMessage {
                margin-bottom: -27px;

                margin-left: 15px;

                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;

                color: #f15530;
            }

            button[type="submit"] {
                transition: background-color 0.5s, color 0.5s;

                &[disabled] {
                    background-color: desaturate(#5ac7a8, 30%);
                    color: #2c2b5e;
                    cursor: not-allowed;
                }

                background: #5ac7a8;
                border-radius: 20px;

                padding: 16px 27px;

                width: max-content;
                margin-top: 25px;

                font-family: "Manrope", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                color: #2c2b5e;

                border: none;
            }

            .checkbox {
                display: flex;
            }

            .pass-label {
                display: flex;
                justify-content: space-between;
            }

            .forgot-pass {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 25px;
                /* identical to box height */
                color: white !important;
                margin-bottom: 0.5rem;
                cursor: pointer;
                text-align: center;
            }

            .successContainer {
                position: relative;
                //margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: #5ac7a8;

                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-size: 28px;
                    line-height: 38px;
                    text-align: center;

                    .text {
                        @media only screen and (max-width: 992px) {
                            display: flex;
                            flex-direction: column;
                        }

                        transition: font-weight 0.5s;
                        font-weight: 700;

                        &:hover {
                            font-weight: 800;
                        }

                        .welcome {
                            @media only screen and (max-width: 992px) {
                                font-size: 28px;
                                line-height: 38px;
                            }
                        }
                    }

                    @media only screen and (max-width: 992px) {
                        flex-direction: column;

                        font-size: 20px;
                        line-height: 27px;

                        margin-bottom: 32px;
                    }
                }

                .bottom {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 44px;
                    /* identical to box height */

                    text-align: center;

                    /* Retro/Retro F */

                    color: #ffffff;

                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    transition: font-weight 0.5s;
                    font-weight: 700;

                    &:hover {
                        font-weight: 800;
                    }

                    @media only screen and (max-width: 992px) {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 25px;
                        text-align: center;
                    }

                    .first {
                        @media only screen and (max-width: 992px) {
                            font-size: 24px;
                            line-height: 33px;
                        }

                        font-size: 36px;
                    }

                    .second {
                        font-size: 20px;
                    }
                }

                button {
                    margin-top: 33px;
                    background: #ee742c;
                    color: white;
                }
            }

            .messages {
                width: 870px;
                display: flex;
                flex-direction: column;
                margin-top: 48px;
                //overflow: hidden;

                @media only screen and (max-width: 992px) {
                    gap: 10px;
                    width: 100%;
                }
                z-index: 0;
                position: relative;

                .path {
                    path {
                        animation: dash 7.5s linear infinite;
                    }

                    position: absolute;
                    z-index: 0;
                    top: 116px;
                    left: -104px;
                }

                .mobilePath {
                    path {
                        animation: dash 7.5s linear infinite;
                    }

                    left: -70px;
                    top: 80px;
                    position: absolute;
                    z-index: 0;
                }

                .row {
                    z-index: 1;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    @media only screen and (max-width: 992px) {
                        flex-direction: column;
                        gap: 10px;
                        //margin-top: 52px;
                        margin-bottom: 52px;
                        img {
                            order: 0;
                            width: 170px;
                            height: 170px;
                            z-index: 2;
                        }
                    }

                    &:not(.right) {
                        img {
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }

                    .text {
                        @media only screen and (max-width: 992px) {
                            order: 1;
                        }

                        font-family: "Manrope", sans-serif;
                        font-style: normal;
                        letter-spacing: -0.02em;
                        font-feature-settings: "ss03" on, "ss02" on;

                        color: #ffffff;

                        max-width: 450px;

                        .headline,
                        .description {
                            transition: font-weight 0.5s, letter-spacing 0.5s;
                        }

                        .headline {
                            font-weight: 600;
                            font-size: 27px;
                            line-height: 37px;
                            margin-bottom: 6px;
                        }

                        .description {
                            font-weight: 200;
                            font-size: 19px;
                            line-height: 144%;
                        }
                    }

                    //&:hover {
                    //    .text {
                    //        .headline {
                    //            font-weight: 700;
                    //            //letter-spacing: -0.022em;
                    //        }
                    //
                    //        .description {
                    //            font-weight: 300;
                    //            //letter-spacing: -0.025em;
                    //        }
                    //    }
                    //}
                }
            }
        }
    }

    .footer {
        &:not(.small) {
            background-color: #0c2146;
        }

        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: normal;

        color: white;

        display: flex;
        gap: 10px;
        flex-direction: column;

        padding: 40px 37px 20px 50px;

        &.small .bottom {
            justify-content: center;
        }

        .bottom {
            display: flex;
            justify-content: space-between;

            .left {
                font-size: 15px;
                line-height: 140%;
            }

            .right {
                font-weight: 400;
                font-size: 16px;
                line-height: 144%;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: -40;
    }
}
