// .content {
//     display: flex;
//     align-items: flex-end;
//     flex-direction: column;
.video-container {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;

    .image,
    .article {
        margin-bottom: 8px;
    }

    .article {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 24px;

        width: 100%;
        height: 50vw;

        > img {
            width: 66px;
            height: 66px;
        }
    }

    .article-button {
        width: 210px;
        height: 56px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        cursor: pointer;

        background: #ffffff;
        border: 0.5px solid #dedede;
        /* shadow 1 */

        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07), 0 14px 24px rgba(0, 0, 0, 0.05);
        border-radius: 12px;

        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        letter-spacing: -0.01em;

        color: #356dff;

        img {
            color: #356dff;
            width: 24px;
            height: 24px;

            position: absolute;
            top: 17px;
            right: 8px;
        }
    }

    .image,
    .video {
        img,
        iframe {
            //border-radius: 24px 24px 0 0;
            width: 100%;
            height: calc((100vw - 60px) / 2);
        }
    }

    .video-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 20px;

        .left {
            flex-shrink: 0;
            flex-basis: 250px;
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: center;
                color: #000000;
                margin-bottom: 8px;
            }

            .publisher {
                cursor: pointer;

                gap: 10px;
                display: flex;
                align-items: center;

                flex-direction: row-reverse;

                img {
                    height: 35px;
                    width: auto;
                    border-radius: 25%;
                }

                .pub-name {
                    //color: white;
                    //color: gray;
                    //font-family: Manrope, sans-serif;;
                    //font-style: normal;
                    //font-weight: 400;
                    //font-size: 16px;
                    //line-height: 16px;

                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 19px;
                    color: #000000;
                }
            }

            flex-grow: 1;
        }
    }
}

// }
.launcher-container {
    margin-left: 15px;
    margin-top: 20px;

    #launcher {
        background: linear-gradient(180deg, #4a96ff 0%, #326cf2 45.83%, #1c4fcf 100%);
        box-shadow: 0px 3px 3px rgba(53, 109, 255, 0.2), 0px 5px 8px rgba(73, 109, 219, 0.1);
        border-radius: 4%;
        width: 142px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        cursor: pointer;

        img {
            height: 38px;
            width: auto;
            border-radius: 50%;
            margin-left: -15px;
        }

        .btn-text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 13px;
            line-height: 18px;
            color: #ffffff;
            margin-left: 9px;
        }
    }
}

.rec-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100vw - 240px);

    .rec-header {
    }

    .rec-item {
        margin-bottom: 30px;

        .rec-topic {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            font-family: Manrope, sans-serif;
        }

        .rec-carousel {
            .carouselItem {
                background: rgba(18, 31, 51, 1);
                border-radius: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px;
                padding-bottom: 20px;
                width: 270px;

                .video {
                    position: relative;
                    width: 270px;
                    height: 130px;
                    overflow: hidden;
                    border-radius: 24px 24px 0 0;

                    iframe {
                        border-radius: 24px 24px 0 0;
                    }

                    .thumbnail {
                        width: 270px;
                        height: 180px;
                        border-radius: 24px 24px 0 0;
                        margin-top: -25px;
                    }

                    .video-overlay {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 270px;
                        height: 130px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-radius: 24px 24px 0 0;

                        img {
                            height: 37px;
                            width: 37px;
                        }
                    }
                }

                .title {
                    padding: 20px 20px;
                    color: white;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    font-family: Manrope, sans-serif;
                    cursor: pointer;
                }

                .publisher {
                    padding: 12px 16px;

                    img {
                        height: 28px;
                        width: auto;
                        margin: 0 10px;
                    }

                    .pub-name {
                        color: white;
                        font-family: Manrope, sans-serif;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

.retain {
    background: #356dff;
    box-shadow: 0px 5px 42px rgba(53, 109, 255, 0.15);
    border-radius: 6px;
    display: flex;
    height: 54px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;

    .dice {
        width: 19px;
        height: 31px;
        margin: 20px;

        img {
            width: 19px;
            height: 31px;
        }
    }

    .text {
        color: #ffffff;

        .primary_text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .secondary_text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            color: rgba(255, 255, 255, 0.8);
        }
    }

    .right_arrow {
        width: 8px;
        height: 5px;
        min-height: 10px;
        position: absolute;
        right: 20px;

        img {
            width: 8px;
            height: 5px;
        }
    }
}

.launch {
    background: #ffffff;
    box-shadow: 0px 5px 42px rgba(53, 109, 255, 0.15);
    border-radius: 6px;
    display: flex;
    height: 54px;
    align-items: center;
    justify-content: flex-start;
    //margin-bottom: 56px;
    position: relative;
    cursor: pointer;

    .dice {
        width: 16px;
        height: 26x;
        margin: 20px;

        img {
            width: 16px;
            height: 26px;
        }
    }

    .text {
        color: #356dff;

        .primary_text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .secondary_text {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            color: #366dff;
        }
    }

    .right_arrow {
        min-height: 22px;
        position: absolute;
        right: 20px;

        img {
            width: 14px;
            height: 22px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .video-container {
        // width: calc(100vw - 230px);
        padding: 35px 40px 0px 40px;
        margin-bottom: 0;

        .image,
        .article {
            margin-bottom: 0px;
        }

        .image,
        .video {
            img,
            iframe {
                //width: calc(100% - 230px);
                width: 100%;
                height: calc((90vw - 230px) / 2);
            }
        }

        .video-data {
            margin-top: 30px;

            .right {
                align-items: flex-end;

                .publisher {
                    flex-direction: row;
                }
            }
        }
    }
}
