.messageBox {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 44px;
    letter-spacing: -0.1px;

    color: #2c2b5e;

    img {
        width: 44px;
        height: 44px;
    }

    svg {
        animation-name: animateSquiggles;
        animation-direction: normal;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@keyframes animateSquiggles {
    0% {
        transform: translateX(-20%);
        stroke-dashoffset: -948;
    }
    100% {
        transform: translateX(20%);
        stroke-dashoffset: 0;
        //2370*2/5 = 948
    }
}
