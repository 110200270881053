.container {
    padding: 6px 26px 8px 26px;
    position: relative;
    height: 100%;
    // background: url("/assets/workspacebackground.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 100%;
    // background-attachment: fixed;
    // background-size: contain;
    // background-position: 0% 100%;
    // background-attachment: fixed;
    // background-color: #eeeef0;
    &.done {
        background: url("/assets/seeyousoon-mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 100%;
        background-attachment: fixed;
    }
    .row {
        //height:100%;
        .content {
            height: -webkit-fill-available;
            height: 100%;
            padding: 25px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
            .carousel-item {
                //margin-right: 10px;
            }
            .card-controls {
                display: flex;
                justify-content: center;
                margin-top: 54px;
                .accept {
                    // margin-left: 10px
                }
                .cancel {
                    margin-right: 10px;
                }
                img {
                    height: 100px;
                    width: auto;
                }
            }
        }
    }
}

.topic-list {
    width: calc(100vw - var(--bs-gutter-x));
    padding-bottom: 80px;
    .header {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        font-family: "Inter";
        line-height: 17px;
        color: black;
        margin-bottom: 25px;
        margin-left: 0px;
        display: flex;
        flex-direction: row;

        .title {
            display: flex;
            .title-text {
                display: flex;
                flex-direction: column;
                justify-content: center;

                margin-left: 13px;
                a {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    text-decoration-line: underline !important;
                    color: #5a5f6b;
                }
            }
        }
        .icon {
            img {
                width: 32px;
                height: 30px;
            }
        }
    }
}

.custom-comp {
    display: flex;
    flex-direction: column;
    align-items: center;
    .nextup {
        font-family: Manrope, sans-serif;
        font-style: italic;
        font-weight: 500;
        font-size: 13px;
        line-height: 23px;
        /* identical to box height, or 177% */
        text-align: center;
        color: #000000;
        margin: 0 0 15px 0;
    }
    .pub-logo {
        margin-bottom: 15px;
        img {
            height: 80px;
            width: auto;
        }
    }
    .media-title {
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        /* or 144% */
        text-align: center;
        color: #000000;
    }
    .publisher-name {
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-align: center;
        color: #000000;
        font-size: 13px;
        line-height: 23px;
        margin-bottom: 20px;
    }
    .next-quiz-accept {
        width: 150px;
        height: 50px;
        background-color: rgba(53, 109, 255, 1);
        box-shadow: 0px 4px 14px rgba(73, 109, 219, 0.2);
        border-radius: 34px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        img {
            height: 62px;
            width: auto;
            margin-left: -5px;
        }
        div {
            font-family: Manrope, sans-serif;
            font-style: normal;
            font-weight: 600;
            color: #fff;
            text-align: center;
            font-size: 14px;
            margin-left: 10px;
        }
    }
}

.rec-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //width: calc(100vw - 60px);
    //@extend: 75vh;
    // padding: 20px 20px;
    overflow-x: hidden;
    .rec-item {
        margin-bottom: 25px;
        cursor: pointer;
        position: relative;
        &::before {
            background-color: #eee;
            content: "";
            position: absolute;
            top: 0;
            left: 0px;
            height: 1px;
            width: 100%;
        }
        padding-top: 25px;
        // border-top: 1px solid #eee;
        // width: calc(100vw - 30px);
        .rec-topic {
            font-style: normal;
            font-family: Manrope, sans-serif;
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
            margin-left: 0px;
            .rec-title {
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
            }
            .rec-see-all {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;

                cursor: pointer;
                color: #356dff;
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .container {
        // background: url("/assets/workspacebackground_medium.png"),
        //     url("/assets/workspacebackground_header.png");
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: 50% 100%;
        // background-attachment: fixed;
        // background-color: #ededef;
        &.done {
            background: url("/assets/seeyousoon-medium.png");
            background-size: cover;
            background-position: 50% 100%;
            background-repeat: no-repeat;
            background-attachment: fixed;
        }
        .row {
            height: calc(100% - 160px);
            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                .carousel {
                    max-width: 338px;
                }
            }
        }
    }
    .rec-container {
        //width: calc(100vw - 520px);
        // width: calc(100vw - 248px);
        // padding: 35px 70px;
        .rec-item {
            // max-width: 1150px;
            // width: calc(100% - 220px);
            // width: calc(100vw - 30px);
            padding-top: 15px;
            margin-bottom: 30px;
            &::before {
                left: 34px;
                width: calc(100% - 35px);
            }
            .rec-topic {
                margin-left: 34px;
            }
        }
        .topic-list {
            width: 100%;
            padding-bottom: 0;
            // width: 100%;
            .header {
                margin-left: 34px;
                margin-bottom: 32px;
            }
        }
    }
}
