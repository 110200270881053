.extension {
    padding: 20px;
    .checkbox {
        display: flex;
    }

    button {
        a,
        a:hover {
            color: white;
            text-decoration: none;
        }
    }
}
