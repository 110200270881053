.spinner {
    width: 50px;
    height: 50px;
    margin: 70px auto 0;
}

.outer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 38px;

    padding: 59px 29px 59px 83px;
    overflow: hidden;

    .title {
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        /* identical to box height */

        display: flex;
        align-items: center;
        letter-spacing: -0.1px;

        color: #2c2b5e;
    }

    .inner {
        flex-grow: 1;
        display: flex;

        .done,
        .empty {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            font-family: Manrope, sans-serif;
            font-style: normal;
            font-size: 28px;
            line-height: 38px;
            text-align: center;
            letter-spacing: -0.1px;

            color: #2c2b5e;
        }

        .done {
            position: relative;
            padding-bottom: 200px;

            .text {
                position: relative;
                font-weight: 700;
                line-height: 68px;

                font-size: 50px;

                color: #5ac7a8;
            }

            .subtext {
                font-weight: 700;
                font-size: 32px;
                line-height: 44px;
            }
        }

        .empty {
            .text {
                margin-top: 32px;
                margin-bottom: 28px;
                font-weight: 400;
            }

            .subtext {
                font-weight: 700;
                margin-bottom: 36px;
            }
        }

        .character {
            position: fixed;
            bottom: -20px;
            left: 35%;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &.small {
                // scale down by 25%, transformed from the bottom right corner
                transform: scale(0.75);
                transform-origin: bottom right;
            }

            .sheachBubble {
                position: relative;
                width: 234px;
                height: 158px;

                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    max-width: 185px;
                    text-align: center;

                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: -0.1px;

                    color: #2c2b5e;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            svg {
                color: #2c2b5e;
                width: 350px;
                height: 350px;
            }
        }

        .review {
            display: flex;
            align-items: stretch;

            gap: 42px;
            height: 234px;

            .left {
                flex-grow: 2;
            }

            .right {
                min-height: 0;
                flex-shrink: 0;
                flex-grow: 2;

                background: #f1f1f8;
                border-radius: 10px;

                flex-basis: 266px;

                display: flex;
                flex-direction: column;

                gap: 16px;

                padding-top: 12px;
                padding-left: 19px;
                padding-right: 21px;

                .top {
                    font-family: Manrope, sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 25px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.1px;

                    color: #2c2b5e;
                }

                .items {
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;

                    gap: 8px;

                    .item {
                        padding: 5px 5px 5px 14px;
                        border-radius: 10px;

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        gap: 11px;

                        font-family: "Manrope", sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: -0.1px;

                        color: #ffffff;

                        img {
                            border-radius: 5px;
                            object-fit: cover;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}

.browse-cards {
    background-color: white;
    padding: 20px;
    flex-grow: 1;
    flex-shrink: 1;

    .cards-title {
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #130b33;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        margin-bottom: 14px;
    }

    .browse-list {
        background: #e1e1eb;
        border-radius: 10px 10px 0px 0px;
        padding: 2px 4px;
        max-height: calc(100vh - 575px);
        overflow: auto;

        .listed-card {
            height: 58px;
            background: #ffffff;
            border-radius: 8px;
            display: flex;
            margin: 4px 0;
            display: flex;
            align-items: center;
            text-align: center;

            img {
                width: 22px;
                height: 22px;
                border-radius: 40px;
                margin-right: 10px;
                margin-left: 16px;
            }

            .lcard-text {
                font-family: Manrope, sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #737373;
            }

            &.active {
                border: 1px solid #356dff;
            }
        }
    }

    .fader {
        background: linear-gradient(180deg, rgba(225, 225, 235, 0) 0%, #e1e1eb 100%);
        position: fixed;
        bottom: 0;
    }
}

@media only screen and (min-width: 992px) {
    .page {
        .empty {
            width: 50%;
        }

        .done {
            width: 100vh;
        }

        .feedback-text-primary {
            font-size: 37px;
            line-height: 37px;
            max-width: 480px;
        }

        .feedback-text-secondary {
            font-size: 16px;
            line-height: 21px;
            max-width: 420px;
        }

        .carousel-container {
            max-width: 1130px;
            margin: 100px auto 0;
            width: 1200px;
        }

        .cards-container {
            width: 860px;
            // height: 760px;
            margin: 0 auto;
            position: relative;

            .prev {
                background: #cdcdda;
                border-radius: 140px;
                cursor: pointer;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 50%;
                // margin-top: -150px;
                img {
                    width: 9px;
                    height: 15px;
                }
            }

            .next {
                background: #cdcdda;
                border-radius: 140px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 50%;
                // margin-top: -150px;
                img {
                    transform: rotate(-180deg);
                    width: 9px;
                    height: 15px;
                }

                cursor: pointer;
            }

            .next-card {
                position: absolute;
                left: 100%;
                top: 0;
                margin-left: 30px;

                .review-card {
                    // margin-top: 0;
                    transform: scale(0.9);
                }
            }

            .prev-card {
                position: absolute;
                right: 100%;
                top: 0;
                margin-right: 30px;

                &.hide {
                    display: none;
                }

                .review-card {
                    //margin-top: 0;
                    transform: scale(0.9);
                }
            }
        }
    }
}
